import React from 'react';

const Spinner = () => (
  <div className="spinner">
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
  </div>
);

export default Spinner;
